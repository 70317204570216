import axios from 'axios'
import { Toast } from 'vant'

const baseURL = process.env.VUE_APP_URL;

const $http = axios.create({
  baseURL,
  timeout: 5000
})

export const $http_app = axios.create({
  baseURL,
  timeout: 5000
})

$http_app.interceptors.response.use(
  response => {
    const res = response.data
    if (res.status != 200) {
      Toast({
        type: 'fail',
        message: res.msg || 'Error',
        duration: 2000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.error('err' + error)
    Toast({
      message: error.message,
      type: 'fail',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

$http.interceptors.response.use(
  response => {
    const res = response.data
    if (res.status != 200) {
      Toast({
        type: 'fail',
        message: res.msg || 'Error',
        duration: 2000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.error('err' + error)
    Toast({
      message: error.message,
      type: 'fail',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default $http
